<template>
  <div>
    <b-row
      v-if="roleId"
      class="mb-3"
    >
      <b-col
        offset-md="2"
        class="text-muted"
      >
        <p class="mb-2">
          สร้างโดย: {{value.createdBy}}
        </p>
      </b-col>
      <b-col class="text-right">
        <b-badge :variant="value.is_delete?'danger':'success'">
          {{ value.is_delete? $t('utils.status.deleted'): $t('utils.status.active')}}
        </b-badge>
      </b-col>
    </b-row>
    <ValidationObserver
      ref="roleForm"
      v-slot="{ handleSubmit }"
    >
      <form @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider
          v-slot="{ errors }"
          :name="$t('roles.name')"
          rules="required"
        >
          <b-form-group
            label-cols-md="2"
            label-cols-sm="12"
            :label="$t('roles.name')"
            label-for="name"
          >
            <b-input-group>
              <b-form-input
                id="name"
                v-model="roleForm.name"
                :state="errors[0] ? false : null"
                :readonly="!!roleId"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </ValidationProvider>
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="2"
          :label="$t('roles.permissions')"
        >
          <b-row>
            <b-col
              v-for="(permission, index) in permissionsData"
              :key="index"
              cols="6"
              class="mb-3"
            >
              <b-form-checkbox
                :id="`permission${index}-${roleId}`"
                v-model="permission.isSelected"
                switch
                :disabled="value && value.agentId === null"
              >
                {{permission.name}}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form-group>
        <b-row v-if="!value || value.agentId">
          <b-col
              v-if="value && value.agentId"
          >
            <b-button
              class="mr-2"
              variant="danger"
              :disabled="isDeletingRole"
              @click="onDelete"
            >
              {{ $t('buttons.remove')}}
            </b-button>
          </b-col>
          <b-col
              v-if="!value || value.agentId"
          >
            <div class="text-right">
              <b-button
                class="mr-2"
                variant="light"
                @click="$emit('cancel')"
              >
                {{ $t('buttons.cancel')}}
              </b-button>
              <b-overlay
                :show="isLoading"
                rounded
                spinner-small
                class="d-inline-block"
              >
                <b-button
                  type="submit"
                  variant="primary"
                >
                  {{ $t('buttons.save')}}
                </b-button>
              </b-overlay>
            </div>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'RoleForm',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      permissionsData: [],
      roleForm: {
        name: '',
        permissionIds: [],
      },
    }
  },
  computed: {
    ...mapState({
      isDeletingRole: (state) => state.role.isDeletingRole,
    }),
    ...mapGetters(['adminPermissions']),
    roleId() {
      return this.value && this.value.id ? this.value.id : null
    },
    rolePermissions() {
      return this.value && this.value.id ? this.value.permissions: []
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.setData()
      }
    }
  },
  created() {
    this.setData()
  },
  methods: {
    ...mapActions(['deleteRole']),
    setData() {
      if (this.value && this.value.id) {
        const data = JSON.parse(JSON.stringify(this.value))
        this.roleForm.id = data.id
        this.roleForm.name = data.name
      } else {
        this.roleForm = {
          name: '',
          permissionIds: [],
        }
      }
      this.setPermissionData()
    },
    setPermissionData() {
      this.permissionsData = []
      if (this.adminPermissions.length > 0) {
        if(this.rolePermissions.length > 0){
          this.adminPermissions.forEach((prop) => {
          const found = this.rolePermissions.find(
            (permission) => permission.id === prop.id
          )
          this.permissionsData.push({
              ...prop,
              isSelected: !!found ,
            })
          })
        } else {
          this.adminPermissions.forEach((prop) => {
            this.permissionsData.push({
              ...prop,
              isSelected: true,
            })
          })
        }
      }
    },
    onSubmit() {
      this.roleForm.permissionIds = this.permissionsData.reduce((acc, val) => {
        if (val.isSelected) {
          acc.push(val.id)
        }
        return acc
      }, [])
      this.$emit('submit', this.roleForm)
    },
    onDelete() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteRole(this.roleId)
          }
        })
    },
  },
}
</script>
